
import { defineComponent, ref, onMounted, computed } from "vue";

export default defineComponent({
  name: "invoice-action",
  props: {
    invoice: Object,
    showInvoiceDetails: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    let invoiceId = ref<string>("");

    const viewReceipt = (invoice) => {
      window.open(invoice.invoice_doc.file_path, "_blank");
    };

    return {
      invoiceId,
      viewReceipt,
    };
  },
});
